const MAIN_YELLOW_COLOR = '#E0EC55';
const MAIN_BLUE_COLOR = '#6B96D5';
const MAIN_GREEN_COLOR = '#85CE85';

const INSTAGRAM_COLOR = '#F9D887';

const LANG = 'ja';

export {
  MAIN_YELLOW_COLOR,
  MAIN_BLUE_COLOR,
  MAIN_GREEN_COLOR,
  INSTAGRAM_COLOR,
  LANG,
};
